import React from "react"
import classNames from "classnames"
import Icon from "components/Icon"

interface IButtonProps {
  id?: string,
  children: JSX.Element | string,
  icon?: string,
  size?: "small" | "default" | "medium",
  type?: string,
  className?: string,
  iconClassName?: string,
  isLoading?: boolean,
  disabled?: boolean,
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
}

const SIZES: { [key: string]: string | undefined } = {
  small: "h-10 rounded-lg px-3",
  default: "h-12 rounded-lg px-4",
  medium: "h-14 rounded-[32px] px-4",
}

const TYPES: { [key: string]: string | undefined } = {
  primary: "bg-primary text-black fill-black",
  secondary: "bg-secondary text-white fill-white",
  outline: "bg-primary/10 text-primary fill-primary",
  danger: "bg-red text-white fill-white",
}

const ICONS: { [key: string]: string | undefined } = {
  small: "w-4.5",
}

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>((
  {
    id,
    children,
    icon,
    size = "default",
    type = "primary",
    className,
    iconClassName,
    isLoading = false,
    disabled = false,
    onClick,
  }, ref) => (
  <button
    id={id}
    ref={ref}
    type="button"
    disabled={disabled || isLoading}
    onClick={onClick}
    className={
      classNames(
        `flex items-center justify-center font-bold ${(disabled || isLoading) && "opacity-50 cursor-not-allowed"}`,
        SIZES[size],
        TYPES[type],
        className,
      )
    }
  >
    {icon && <Icon
      name={icon}
      className={
        classNames(
          `mr-2 ${iconClassName}`,
          ICONS[size],
        )
      }
    />
    }
    {children}
  </button>
))

export default Button

