import { combineReducers } from "redux"
import { userReducer } from "slice/user"
import { campaignReducer } from "slice/campaign"
import { languageReducer } from "slice/language"
import { settingReducer } from "slice/setting"
import { commonReducer } from "slice/common"
import { productFiltersReducer } from "slice/productFilters"
import { productSorterReducer } from "slice/productSorter"
import { notificationReducer } from "slice/notification"


export default combineReducers({
  user: userReducer,
  campaign: campaignReducer,
  language: languageReducer,
  setting: settingReducer,
  common: commonReducer,
  productFilters: productFiltersReducer,
  productSorter: productSorterReducer,
  notification: notificationReducer,
})
