import { IUser } from "model/user"
import {
  IPayloadLogin,
  IPayloadResetPassword,
  IPayloadVerifyOTP,
  IValidateAccountRequest,
} from "slice/user/types"
import axios from "../axios"

const CONTROLLER_NAME = "/auth"

export const getRequestOtpRegister = (phone: string) => {
  return axios.post(`${CONTROLLER_NAME}/otp/request`, { phone })
}

export const verifyOtp = (dataOPT: IPayloadVerifyOTP) => {
  return axios.post(`${CONTROLLER_NAME}/otp/verify`, dataOPT)
}

export const registerUser = (dataUserRegister: any) => {
  return axios.post(`${CONTROLLER_NAME}/register`, dataUserRegister)
}

export const registerCooperate = (dataUserRegister: any) => {
  return axios.post(`${CONTROLLER_NAME}/register`, dataUserRegister)
}

export const login = (dataLogin: IPayloadLogin) => {
  return axios.post(`${CONTROLLER_NAME}/login`, dataLogin)
}

export const getMe = () => {
  return axios.get(`/user/profile`)
}

export const deleteAccount = () => {
  return axios.delete("/user")
}

export const getRequestOtp = (phone: string) => {
  return axios.post(`${CONTROLLER_NAME}/forgot-password`, { phone })
}

export const resetPassword = (dataReset: IPayloadResetPassword) => {
  return axios.post(`${CONTROLLER_NAME}/reset-password`, dataReset)
}

export const updateProfile = (payload: IUser) => {
  return axios.put("/user/profile", payload)
}

export const updateAvatar = (filename: string) => {
  return axios.put("/user/profile/avatar", {
    filename,
  })
}

export const validateAccount = (payload: IValidateAccountRequest) => {
  return axios.patch(`${CONTROLLER_NAME}/register`, payload)
}

export const getRefreshToken = (refreshToken: string, userId: string) => {
  return axios.post("auth/refresh-token", {
    refreshToken,
    userId,
  })
}
