import { createSlice } from "@reduxjs/toolkit"
import { IProduct, IScheduleProps, ISelectedTimeSlotsProps } from "model"

interface ICampaignCreatingProps {
  id: string
  campaignId: string
  title: string
  mediaType: string
  position: string
  products: IProduct[]
  schedule: IScheduleProps[]
  selectedTimeSlots: ISelectedTimeSlotsProps
  bookingSlotType: string,
}

const initCampaign: ICampaignCreatingProps = {
  id: "",
  campaignId: "",
  title: "",
  mediaType: "",
  position: "",
  products: [],
  schedule: [],
  selectedTimeSlots: {
    normal: 1,
    golden: 0,
    spotsNumber: 0,
  },
  bookingSlotType: "",
}

const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    campaign: initCampaign,
    filters: {
      utilities: [],
      positions: [],
    },
  },
  reducers: {
    setCampaign: (state, action) => {
      state.campaign = action.payload
    },
    setCampaignFilters: (state, action) => {
      state.filters = action.payload
    },
    resetCampaign: (state) => {
      state.campaign = initCampaign
    },
    resetCampaignFilter: (state) => {
      state.filters = {
        utilities: [],
        positions: [],
      }
    },
    setProductsCampaign: (state, action) => {
      state.campaign.products = action.payload
    },
  },
})

export const {
  reducer: campaignReducer,
  actions: {
    setCampaign,
    setCampaignFilters,
    resetCampaign,
    resetCampaignFilter,
    setProductsCampaign,
  },
} = campaignSlice
