import classNames from "classnames"

import ButtonIcon from "components/ButtonIcon"

const Drawer = ({ children, open, onClose, contentClassName, rightHeader }: any) => {
  return (
    <div>
      {open && (
        <button
          type={"button"}
          className="fixed h-full w-full top-0 right-0 !m-0 bg-primary/50 cursor-pointer z-40"
          onClick={onClose} />
      )}
      <div
        className={classNames(
          "fixed right-0 top-0 w-170 z-[100] h-full bg-white translate-x-full ease-out duration-300 flex flex-col",
          {
            "translate-x-0": open,
            [contentClassName]: true,
          },
        )}
      >
        <div className="flex items-center justify-between shadow-sm h-[80px] px-[34px]">
          <ButtonIcon name="close-circle" className="fill-red" onClick={onClose} />
          {rightHeader}
        </div>
        <div className="flex flex-col h-[calc(100vh-96px)]">{children}</div>
      </div>
    </div>
  )
}

export default Drawer
