import { createSlice } from "@reduxjs/toolkit"
import {
  getMe,
  getRequestOtpRegister,
  login,
  verifyOtp,
  registerCooperate,
  registerUser,
  getRequestOtp,
  resetPassword,
  updateProfile,
} from "./action"
import { IUserState } from "./types"

const initialState: IUserState = {
  loading: false,
  currentUser: null,
  newUserInfo: null,
  accessToken: "",
  requestId: "",
  phone: "",
  verifyOtpStatus: false,
  isResetPassword: false,
  isCorporateAccount: false,
}
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAccessToken: (state: IUserState, action) => {
      state.accessToken = action.payload.accessToken
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
      state.isCorporateAccount = action.payload?.corporateProfile?.verified
    },
    setNewUserInfo: (state: IUserState, action) => {
      state.newUserInfo = action.payload
    },
    setVerifyOtp: (state, action) => {
      state.verifyOtpStatus = action.payload
    },
    setPhone: (state, action) => {
      state.phone = action.payload
    },
    setIsResetPassword: (state, action) => {
      state.isResetPassword = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      //#region getRequestOtpRegister
      .addCase(getRequestOtpRegister.pending, (state) => {
        state.loading = true
      })
      .addCase(getRequestOtpRegister.fulfilled, (state, action: any) => {
        state.loading = false
        state.requestId = action.payload.requestId
      })
      .addCase(getRequestOtpRegister.rejected, (state, action: any) => {
        state.loading = false
        state.requestId = ""
      })
      //#endregion

      //#region verifyOtp
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true
      })
      .addCase(verifyOtp.fulfilled, (state, action: any) => {
        state.loading = false
        state.verifyOtpStatus = action.payload
      })
      .addCase(verifyOtp.rejected, (state, action: any) => {
        state.loading = false
        state.verifyOtpStatus = false
      })
      //#endregion

      //#region getMe
      .addCase(getMe.pending, (state: IUserState) => {
        state.loading = true
      })
      .addCase(getMe.fulfilled, (state, action: any) => {
        state.loading = false
        state.currentUser = action.payload
        state.isCorporateAccount = action.payload?.corporateProfile?.verified
      })
      .addCase(getMe.rejected, (state, action: any) => {
        state.loading = false
        state.currentUser = null
      })
      //#endregion

      //#region login
      .addCase(login.pending, (state) => {
        state.loading = true
      })
      .addCase(login.fulfilled, (state, action: any) => {
        state.loading = false
        state.accessToken = action.payload
      })
      .addCase(login.rejected, (state, action: any) => {
        state.loading = false
        state.accessToken = ""
      })
      //#endregion

      // Register personal
      .addCase(registerUser.pending, (state: IUserState) => {
        state.loading = true
      })
      .addCase(registerUser.fulfilled, (state: IUserState, action: any) => {
        state.loading = false
        state.newUserInfo = null
        state.accessToken = ""
        state.requestId = ""
        state.phone = ""
        state.verifyOtpStatus = false
      })
      .addCase(registerUser.rejected, (state: IUserState, action: any) => {
        state.loading = false
      })
      // Register cooperate
      .addCase(registerCooperate.pending, (state: IUserState) => {
        state.loading = true
      })
      .addCase(registerCooperate.fulfilled, (state: IUserState, action: any) => {
        state.loading = false
        state.newUserInfo = null
        state.accessToken = ""
        state.requestId = ""
        state.phone = ""
        state.verifyOtpStatus = false
      })
      .addCase(registerCooperate.rejected, (state: IUserState, action: any) => {
        state.loading = false
      })

      //#region getRequestOtp
      .addCase(getRequestOtp.pending, (state) => {
        state.loading = true
      })
      .addCase(getRequestOtp.fulfilled, (state, action: any) => {
        state.loading = false
        state.requestId = action.payload.requestId
      })
      .addCase(getRequestOtp.rejected, (state, action: any) => {
        state.loading = false
        state.requestId = ""
      })
      //#endregion

      //#region resetPassword
      .addCase(resetPassword.pending, (state) => {
        state.loading = true
      })
      .addCase(resetPassword.fulfilled, (state, action: any) => {
        state.loading = false
        state.requestId = ""
        state.phone = ""
      })
      .addCase(resetPassword.rejected, (state, action: any) => {
        state.loading = false
      })
      //#endregion

      //#region updateProfile
      .addCase(updateProfile.pending, (state) => {
        state.loading = true
      })
      .addCase(updateProfile.fulfilled, (state, action: any) => {
        state.currentUser = action.payload
        state.isCorporateAccount = action.payload?.corporateProfile?.verified
      })
      .addCase(updateProfile.rejected, (state, action: any) => {
        state.loading = false
      })
    //#endregion
  },
})

export const { reducer: userReducer } = userSlice

export const {
  setAccessToken,
  setCurrentUser,
  setNewUserInfo,
  setVerifyOtp,
  setPhone,
  setIsResetPassword,
} = userSlice.actions
