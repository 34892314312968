import React, { useEffect, useRef } from "react"
import ButtonIcon from "../ButtonIcon"

interface IModalProps {
  visibleModal: boolean
  wrapperClassName?: string
  contentClassName?: string
  bodyClassName?: string
  children?: React.ReactElement | undefined
  onClose: () => void
  onConfirm?: () => void
}

const Modal = (
  {
    visibleModal,
    wrapperClassName = "",
    contentClassName = "",
    bodyClassName = "",
    children,
    onClose,
  }: IModalProps) => {
  const modalRef = useRef<any>(null)

  useEffect(() => {}, [visibleModal])

  return (
    <>
      <div
        className={`m-auto px-6 justify-center items-center flex overflow-hidden fixed inset-0 z-[101] outline-none focus:outline-none transition-all duration-300 max-w-[940px] w-v-fit-content ${wrapperClassName} ${
          visibleModal ? "visible opacity-1" : "invisible opacity-0"
        }`}
      >
        <div
          ref={modalRef}
          className={`relative p-6 rounded-lg shadow-lg overflow-y-auto overflow-x-hidden max-h-[90vh] min-h-[460px] bg-white w-fit ${contentClassName}`}
        >
          <ButtonIcon
            name="close-circle"
            className="absolute top-6 right-6 bg-red"
            iconClassName="fill-white"
            onClick={onClose}
          />
          <div className={bodyClassName}>{children}</div>
        </div>
      </div>
      <button
        onClick={onClose}
        className={`fixed inset-0 z-[100] bg-primary/70 transition-all duration-300 ${
          visibleModal ? "visible opacity-1" : "invisible opacity-0"
        }`}
      />
    </>
  )
}

export default Modal
