import { forwardRef } from "react"
import SVG from "react-inlinesvg"

interface IIconProps {
  name?: string,
  uri?: string | undefined,
  className?: string | undefined,
  color?: string,
  size?: number,
  height?: number,
  width?: number,
  onClick?: () => void
}

const Icon = forwardRef<SVGElement, IIconProps>(
  ({ name, uri, className, color, height, width, size = 22, ...props }, ref) => {
    return <SVG fill={color} className={className} height={height || size} width={width || size} innerRef={ref}
                src={uri || `/icons/${name}.svg`} {...props} />
  },
)

export default Icon
