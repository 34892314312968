import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import ReactDOM from "react-dom"

import Drawer from "components/Drawer"
import Button from "components/Button"
import Tag from "components/Tag"
import { useAppDispatch, useAppSelector } from "state/store"
import * as notificationApi from "services/api/notification"
import { setNotifications, setNotificationsUnread } from "slice/notification"
import { INotification } from "model/notification"
import { setCommonLoading } from "slice/common"
import { getErrorMessage } from "utils/error"
import ButtonIcon from "../../ButtonIcon"
import Modal from "../../Modal"

interface NotificationsDrawerProps {
  openDrawer: boolean,
  onClose: () => void,
}

interface IReadAllNotificationsModalProps {
  visibleModal: boolean
  onSetVisibleModal: (value: boolean) => void
  onConfirm: () => void
  t: any
}

const ReadAllNotificationConfirmationModal = (
  {
    visibleModal,
    onSetVisibleModal,
    onConfirm,
    t,
  }: IReadAllNotificationsModalProps) => {
  return ReactDOM.createPortal(
    <Modal visibleModal={visibleModal} onClose={() => onSetVisibleModal(false)}>
      <div className={"flex flex-col items-center p-6 space-y-4"}>
        <img src="/images/read-all-notifications.png" alt="read all notifications" />
        <p>
          {t("areYouSureYouWannaMarkAllTheUnreadNotificationsToRead")}
        </p>
        <Button
          id={"notifications__readAll"}
          className={"font-bold uppercase"}
          onClick={onConfirm}
        >
          {t("confirm")}
        </Button>
        <button onClick={() => onSetVisibleModal(false)} className={"text-red font-bold"}>
          {t("cancel")}
        </button>
      </div>
    </Modal>,
    // @ts-ignore
    document.querySelector("body"),
  )
}

const NotificationsDrawer = (
  {
    openDrawer,
    onClose,
  }: NotificationsDrawerProps) => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const notifications = useAppSelector((state) => state.notification.notifications)

  const [page, setPage] = useState(1)
  const [totalNotifications, setTotalNotifications] = useState(0)
  const [loadMore, setLoadMore] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)

  const fetchNotifications = useCallback(
    async (pageNumber: number) => {
      try {
        dispatch(setCommonLoading(true))
        const res = await notificationApi.getNotifications({
          page: pageNumber,
          perPage: 20,
        })
        if (res.data) {
          setTotalNotifications(res.data.total)
          if (loadMore) {
            dispatch(setNotifications([...notifications, ...res.data.notifications]))
          } else {
            dispatch(setNotifications(res.data.notifications))
          }
        }
      } catch (error) {
        toast.error(t(getErrorMessage(error)))
      } finally {
        setLoadMore(false)
        dispatch(setCommonLoading(false))
      }
    },
    [dispatch, loadMore, notifications, t],
  )

  const hasNotificationsUnread = useMemo(() => {
    return notifications.some((notification: INotification) => !notification.seen)
  }, [notifications])

  useEffect(() => {
    ;(async () => {
      if (openDrawer) {
        setPage(1)
        await fetchNotifications(page)
      }
    })()
  }, [openDrawer, page])

  const chooseNotification = useCallback(
    async (notification: INotification) => {
      switch (notification?.data?.type) {
        case "ORDER_STATUS_CHANGE":
        case "PAYMENT_REMINDER":
          navigate(`/my-orders/${notification?.data?.orderId}`)
          break
        case "CAMPAIGN_STATUS_CHANGE":
          navigate(`/campaign/${notification?.data?.campaignId}`)
          break
        case "PASSWORD_CHANGE":
          navigate("/my-profile")
          break
        default:
          break
      }
      if (!notification?.seen) {
        try {
          await notificationApi.updateNotification(notification?.data?.notificationId, {
            seen: true,
          })
        } catch (error) {
          toast.error(t(getErrorMessage(error)))
        }
      }
      onClose()
    },
    [navigate, onClose, t],
  )

  const getNotificationIcon = (notification: INotification) => {
    switch (notification?.data?.type) {
      case "ORDER_STATUS_CHANGE":
      case "PAYMENT_REMINDER":
        return "box"
      case "CAMPAIGN_STATUS_CHANGE":
        return "star"
      default:
        return "box"
    }
  }

  const handleReadAllNotifications = useCallback(async () => {
    try {
      dispatch(setCommonLoading(true))
      await notificationApi.readAllNotifications()
      dispatch(setNotificationsUnread([]))
      setNotifications([])
      await fetchNotifications(1)
      setVisibleModal(false)
    } catch (error) {
      toast.error(t(getErrorMessage(error)))
    } finally {
      dispatch(setCommonLoading(false))
    }
  }, [dispatch, fetchNotifications, t])

  return (
    <Drawer
      open={openDrawer}
      onClose={onClose}
      contentClassName="w-[500px]"
      rightHeader={
        <ButtonIcon
          name={"task-square"}
          isDisabled={!hasNotificationsUnread}
          onClick={() => setVisibleModal(true)} />
      }
    >
      <div
        className={`overflow-y-auto flex-1 p-6 space-y-6 ${
          notifications.length < totalNotifications ? "pb-14" : "pb-4"
        }`}
      >
        {notifications
          .filter((notification: INotification) => !!notification.body)
          .map((notification: INotification) => {
            return (
              <button
                id={"notification__chooseNotification"}
                key={notification._id}
                className="flex items-center justify-start text-left space-x-4"
                onClick={() => chooseNotification(notification)}
              >
                <div className="w-11 h-11 rounded-full flex items-center justify-center relative bg-primary/10">
                  <Tag icon={getNotificationIcon(notification)} />
                  {!notification.seen && (
                    <div className="w-2 h-2 rounded-full bg-red absolute top-1 right-1" />
                  )}
                </div>
                <p
                  className={classNames("flex-1", {
                    "font-bold": !notification.seen,
                    "font-normal text-gray": notification.seen,
                  })}
                >
                  {locale === "en" ? notification.data.en : notification.data.vi}
                </p>
              </button>
            )
          })}
        {notifications.length < totalNotifications && (
          <div className="absolute bottom-1 left-0 w-full flex justify-center">
            <Button
              id={"notifications__loadMore"}
              onClick={() => {
                setLoadMore(true)
                setPage(page + 1)
              }}
            >
              {t("loadMore")}
            </Button>
          </div>
        )}
      </div>

      <ReadAllNotificationConfirmationModal
        t={t}
        visibleModal={visibleModal}
        onSetVisibleModal={setVisibleModal}
        onConfirm={handleReadAllNotifications}
      />
    </Drawer>
  )
}

export default NotificationsDrawer
