import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { toast, ToastContainer } from "react-toastify"
import { useAppDispatch, useAppSelector } from "state/store"

import MainLayout from "layouts/MainLayout"
import MainRoutes from "router/MainRoutes"
import * as settingApi from "services/api/settings"
import { setCommonLoading } from "./slice/common"
import { setProductLocations, setSettings } from "./slice/setting"
import { getErrorMessage } from "./utils/error"

function App() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.user.currentUser)

  useEffect(() => {
    ;(async () => {
      try {
        dispatch(setCommonLoading(true))
        const res = await settingApi.getSettings()
        if (res.data) {
          dispatch(setSettings(res.data))
        }
      } catch (error) {
        toast.error(t(getErrorMessage(error)))
      } finally {
        dispatch(setCommonLoading(false))
      }
    })()
  }, [currentUser, dispatch, t])

  useEffect(() => {
    ;(async () => {
      try {
        dispatch(setCommonLoading(true))
        const res = await settingApi.getProductLocations()
        if (res.data) {
          dispatch(setProductLocations(res.data.locations))
        }
      } catch (error) {
        toast.error(t(getErrorMessage(error)))
      } finally {
        dispatch(setCommonLoading(false))
      }
    })()
  }, [dispatch, t])

  // console.log('currentUser', currentUser)
  //
  // if (!currentUser || isEmpty(currentUser)) {
  //   return (
  //     <AuthLayout>
  //       <AuthRoutes />
  //       <ToastContainer />
  //     </AuthLayout>
  //   )
  // }

  return (
    <MainLayout>
      <MainRoutes />
      <ToastContainer />
    </MainLayout>
  )
}

export default App
