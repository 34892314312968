import { Link, useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import Avatar from "react-avatar"
import { useMemo, useState } from "react"

import { useAppSelector } from "state/store"
import Icon from "components/Icon"
import NotificationsDrawer from "./Components/NotificationsDrawer"
import { INotification } from "../../model/notification"
import Button from "../Button"

interface IMenuItemProps {
  key: string
  url: string
  icon: string
}

const MENU = [
  {
    key: "products",
    url: "/home",
    icon: "monitor",
  },
  {
    key: "myCampaigns",
    url: "/campaigns",
    icon: "star",
  },
  {
    key: "mediaPlan",
    url: "/media-plans",
    icon: "document-like",
  },
]

const Header = () => {
  let location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentUser = useAppSelector((state) => state.user.currentUser)
  const notifications = useAppSelector((state) => state.notification.notifications)

  const [openNotificationsDrawer, setOpenNotificationsDrawer] = useState(false)

  const routeActive = (item: IMenuItemProps) => {
    return item.url === "/home" ? location?.pathname === "/home" : location?.pathname?.startsWith(item.url)
  }

  const hasNotificationsUnread = useMemo(() => {
    return notifications.some((notification: INotification) => !notification.seen)
  }, [notifications])

  return (
    <>
      <div className="h-20 px-20 bg-white w-full z-1 shadow-sm flex items-center justify-between z-30">
        <div className="flex items-center">
          <div className="mr-20">
            <Link to="/">
              <img className={"w-[80%]"} src="/images/logo.svg" alt="Logo" />
            </Link>
          </div>
          <ul className="flex items-center space-x-6">
            {MENU.map((item) => (
              <li key={`str-item.key-${item.key}`} className={item.key}>
                <Link
                  id={`header__${item.key}`}
                  to={(currentUser || item.key === "products") ? item.url : "/sign-in"}
                  className={classNames("flex items-center h-10 rounded-xl px-5", {
                    "bg-primary": routeActive(item),
                    "bg-primary/10": !routeActive(item),
                  })}
                >
                  <Icon
                    name={item.icon}
                    className={`${routeActive(item) ? "fill-black" : "fill-black"} mr-2`}
                  />
                  <span className={routeActive(item) ? "text-black" : "text-black"}>
                    {t(item.key)}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center space-x-4">
          {
            currentUser ?
              <>
                <Link to="/my-profile" id={"header__myProfile"}>
                  <Avatar
                    src={currentUser?.avatar?.url}
                    name={`${currentUser?.firstName} ${currentUser?.lastName}`}
                    size="40px"
                    className="rounded-full !bg-primary !text-black"
                  />
                </Link>
                <button
                  className="relative p-1"
                  onClick={() => setOpenNotificationsDrawer(true)}
                  id={"header__notifications"}
                >
                  <Icon name="notification" className="fill-secondary" />
                  {hasNotificationsUnread && (
                    <div className="w-2 h-2 bg-red rounded-full absolute top-0 right-1" />
                  )}
                </button>
              </>
              :
              <>
                <Button size={"small"} onClick={() => navigate("/sign-in")}>{t("signIn")}</Button>
                <Button size={"small"} type={"secondary"} onClick={() => navigate("/sign-up")}>{t("signUp")}</Button>
              </>
          }
        </div>
      </div>
      <NotificationsDrawer
        openDrawer={openNotificationsDrawer}
        onClose={() => setOpenNotificationsDrawer(false)}
      />
    </>
  )
}

export default Header
