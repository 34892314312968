import Icon from "components/Icon"
import classNames from "classnames"

interface ITagProps {
  text?: any,
  secondary?: boolean,
  icon?: string,
  iconUri?: string,
  className?: string,
  iconClassName?: string,
  textClassName?: string,
  onClick?: () => void
}

const Tag = ({ text, secondary, className, icon, iconUri, iconClassName, textClassName = "", onClick }: ITagProps) => {
  return (
    <div
      className={
        classNames(`${secondary ? "bg-black" : "bg-primary"} flex justify-center items-center space-x-2 rounded-2xl h-8`, {
            "px-5": text,
            "w-8 !rounded-full": (icon || iconUri) && !text,
          },
          className,
        )}
      onClick={() => onClick && onClick()}
    >
      {icon && <Icon className={`${secondary ? "fill-primary" : "fill-black"} w-5 ${iconClassName}`} name={icon} />}
      {iconUri &&
        <Icon className={`${secondary ? "fill-primary" : "fill-black"} w-5 ${iconClassName}`} uri={iconUri} />}
      {text &&
        <span
          className={`${secondary ? "text-primary" : "text-black"} font-bold text-12 ${textClassName}`}>{text}</span>}
    </div>
  )
}

export default Tag
