import { createSlice } from "@reduxjs/toolkit"
import { IProductFilters } from "model"

const initialState: IProductFilters = {
  mediaType: "",
  advertisingPosition: [],
  utilities: [],
  positions: [],
  schedule: [],
  productIDs: [],
  selectedTimeSlots: {
    normal: 0,
    golden: 0,
    spotsNumber: 0,
  },
  bookingSlotType: "",
}

const productFiltersSlice = createSlice({
  name: "productFilters",
  initialState: {
    filters: initialState,
  },
  reducers: {
    setProductFilters: (state, action) => {
      state.filters = action.payload
    },
    resetProductFilter: (state) => {
      state.filters = initialState
    },
  },
})

export const {
  reducer: productFiltersReducer,
  actions: { setProductFilters, resetProductFilter },
} = productFiltersSlice
