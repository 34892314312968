import { createSlice } from "@reduxjs/toolkit"
import { ISettingState } from "./types"

const initialState: ISettingState = {
  settings: {
    LEDTimeSlotConfiguration: [],
    corporateAccountConfigs: {
      bookingWaitingTimeInDays: 0,
      minimumPaymentAmountInPercentages: 0,
      userType: "",
    },
    memberLevelConfiguration: {
      memberLevels: [],
      pointConfiguration: {
        transactionAmount: 0,
        point: 0,
      },
    },
    personalAccountConfigs: {
      bookingWaitingTimeInDays: 0,
      minimumPaymentAmountInPercentages: 0,
      userType: "",
    },
    bannerConfiguration: [],
    LED15sMinSlotsBooking: 1,
  },
  exchangeRates: {
    base: "USD",
    rates: {
      VND: 23500,
    },
  },
  productLocations: [],
}

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSettings: (state: ISettingState, action) => {
      state.settings = action.payload
    },
    setExchangeRates: (state: ISettingState, action) => {
      state.exchangeRates = action.payload
    },
    setProductLocations: (state: ISettingState, action) => {
      state.productLocations = action.payload
    },
  },
})

export const {
  reducer: settingReducer,
  actions: { setSettings, setExchangeRates, setProductLocations },
} = settingSlice
