import { createSlice } from "@reduxjs/toolkit"

const productSorterSlice = createSlice({
  name: "productSorter",
  initialState: {
    sorter: {
      sortByPrice: null,
      sortBySale: false,
    },
  },
  reducers: {
    setProductSorter: (state, action) => {
      state.sorter = action.payload
    },
    resetProductSorter: (state, action) => {
      state.sorter = {
        sortByPrice: null,
        sortBySale: false,
      }
    },
  },
})

export const {
  reducer: productSorterReducer,
  actions: { setProductSorter, resetProductSorter },
} = productSorterSlice
