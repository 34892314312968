import Icon from "components/Icon"
import React from "react"

interface IButtonIconProps {
  id?: string
  name: string
  onClick?: () => void,
  iconClassName?: string,
  isUri?: boolean,
  isDisabled?: boolean,
  className?: string
}

const ButtonIcon = (
  {
    id,
    name,
    iconClassName = "",
    className = "",
    isUri = false,
    isDisabled = false,
    onClick,
  }: IButtonIconProps) =>
  <button
    id={id}
    disabled={isDisabled}
    onClick={onClick}
    type="button"
    className={`w-6 h-6 bg-primary rounded-full flex items-center justify-center ${className} ${isDisabled ? "opacity-[0.5] cursor-not-allowed" : ""}`}
  >
    {
      isUri ? <Icon uri={name} size={20} className={`fill-black ${iconClassName}`} />
        : <Icon name={name} size={20} className={`fill-black ${iconClassName}`} />
    }
  </button>

export default ButtonIcon
