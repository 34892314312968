import axios from "../axios"

const CONTROLLER_NAME = "notifications/"

export const getNotifications = (params?: any) => {
  return axios.get(CONTROLLER_NAME, {
    params,
  })
}

export const updateNotification = (notificationId: string, payload: any) => {
  return axios.put(`${CONTROLLER_NAME}${notificationId}`, payload)
}

export const readAllNotifications = () => {
  return axios.post(`${CONTROLLER_NAME}readAll`)
}
