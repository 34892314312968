import { createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import jwtDecode from "jwt-decode"

import * as userApi from "services/api/users"
import {
  IPayloadLogin,
  IPayloadRegisterCooperate,
  IPayloadRegisterUser,
  IPayloadResetPassword,
  IPayloadVerifyOTP,
} from "./types"
import { setNewUserInfo, setPhone } from "slice/user"
import { IUser } from "model/user"
import { getErrorMessage } from "utils/error"

export const backupUserInfo = createAsyncThunk(
  "auth/setNewUserInfo",
  async (payload: any, thunkApi) => {
    try {
      thunkApi.dispatch(setNewUserInfo(payload))
      thunkApi.dispatch(setPhone(payload.phone))
      await thunkApi.dispatch(getRequestOtpRegister({ phone: payload.phone }))
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const getRequestOtpRegister = createAsyncThunk(
  "auth/getRequestOtpRegister",
  async (param: any, thunkApi) => {
    try {
      const response = await userApi.getRequestOtpRegister(param.phone)
      return response.data
    } catch (error: any) {
      toast.error(getErrorMessage(error))
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (dataOPT: IPayloadVerifyOTP, thunkApi) => {
    try {
      const response = await userApi.verifyOtp(dataOPT)
      return response.status === 202 || response.status === 200
    } catch (error: any) {
      toast.error(error.response.data.message)
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const login = createAsyncThunk("auth/login", async (dataLogin: IPayloadLogin, thunkApi) => {
  try {
    const data = await userApi.login(dataLogin)
    const token = data.data.accessToken
    if (token != null) {
      const decodedToken: any = jwtDecode(token)
      if (decodedToken?.roles?.includes("admin")) {
        toast.error(
          "Chỉ khách hàng mới được đăng nhập ứng dụng, đội ngũ admin vui lòng đăng nhập vào hệ thống quản lý",
        )
        return
      }
      localStorage.setItem("accessToken", token)
    }
  } catch (error: any) {
    toast.error(error.response.data.message)
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (payload: IPayloadRegisterUser) => {
    try {
      const response = await userApi.registerUser(payload)
      return response.data
    } catch (error: any) {
      console.error(error.message)
    }
  },
)

export const registerCooperate = createAsyncThunk(
  "auth/registerCooperate",
  async (payload: IPayloadRegisterCooperate) => {
    try {
      const response = await userApi.registerCooperate(payload)
      return response.data
    } catch (error: any) {
      console.error(error.message)
    }
  },
)

export const getMe = createAsyncThunk("auth/getMe", async (params: any, thunkApi) => {
  try {
    const response = await userApi.getMe()
    return response.data
  } catch (error: any) {
    toast.error(error.response.data.message)
    localStorage.setItem("accessToken", "")
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getRequestOtp = createAsyncThunk(
  "auth/getRequestOtp",
  async (param: any, thunkApi) => {
    try {
      const response = await userApi.getRequestOtp(param.phone)
      return response.data
    } catch (error: any) {
      toast.error(error.response.data.message)
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (payload: IPayloadResetPassword) => {
    try {
      const response = await userApi.resetPassword(payload)
      return response.data
    } catch (error: any) {
      console.error(error.message)
    }
  },
)

export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (payload: IUser, thunkApi) => {
    try {
      const response = await userApi.updateProfile(payload)
      return response.data
    } catch (error: any) {
      toast.error(error.response.data.message)
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)
