import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"

const Components = React.lazy(() => import("pages/UiGuideLine"))
const CampaignPage = React.lazy(() => import("pages/Campaign/MyCampaign"))
const CampaignDetailPage = React.lazy(() => import("pages/Campaign/CampaignDetail"))
const EditCampaignPage = React.lazy(() => import("pages/Campaign/UpsertCampaign"))
const CreateCampaignPage = React.lazy(() => import("pages/Campaign/UpsertCampaign"))
const MediaPlansPage = React.lazy(() => import("pages/MediaPlans/MediaPlans"))
const MediaPlanDetailPage = React.lazy(() => import("pages/MediaPlans/MediaPlanDetail"))
const EditMediaPlanPage = React.lazy(() => import("pages/MediaPlans/UpsertMediaPlan"))
const CreateMediaPlanPage = React.lazy(() => import("pages/MediaPlans/UpsertMediaPlan"))
const ProductsPage = React.lazy(() => import("pages/Products"))
const ProductCategoryPage = React.lazy(() => import("pages/Products/ProductCategory"))
const MapPage = React.lazy(() => import("pages/Map"))
const SavedProductsMapPage = React.lazy(() => import("pages/Map"))
const MyProfile = React.lazy(() => import("pages/MyProfile"))
const MyOrders = React.lazy(() => import("pages/MyOrders"))
const OrderDetail = React.lazy(() => import("pages/OrderDetail"))
const MyTransactions = React.lazy(() => import("pages/MyTransactions"))
const Settings = React.lazy(() => import("pages/Settings"))
const SavedProducts = React.lazy(() => import("pages/SavedProducts"))

const SignIn = React.lazy(() => import("pages/Auth/SignIn"))
const SignUp = React.lazy(() => import("pages/Auth/SignUp"))
const ForgotPassword = React.lazy(() => import("pages/Auth/ForgotPassword"))
const ResetPassword = React.lazy(() => import("pages/Auth/ResetPassword"))
const VerifyPhoneNumber = React.lazy(() => import("pages/Auth/VerifyPhoneNumber"))
const ChooseYourAccountType = React.lazy(() => import("pages/Auth/ChooseYourAccountType"))
const FillInYourInformation = React.lazy(() => import("pages/Auth/FillInYourInformation"))
const UiGuideLine = React.lazy(() => import("pages/UiGuideLine"))

const MainRoutes = () => {
  return (
    <React.Suspense>
      <Routes>
        <Route path="/home" element={<ProductsPage />} />
        <Route path="/products/:category" element={<ProductCategoryPage />} />
        <Route path="/campaigns" element={<CampaignPage />} />
        <Route path="/campaign/:id" element={<CampaignDetailPage />} />
        <Route path="/create-campaign" element={<EditCampaignPage />} />
        <Route path="/campaign/edit/:id" element={<EditCampaignPage />} />
        <Route path="/campaign/new" element={<CreateCampaignPage />} />
        <Route path="/campaign/:campaignId/order/:id" element={<OrderDetail />} />
        <Route path="/media-plans" element={<MediaPlansPage />} />
        <Route path="/media-plans/:id" element={<MediaPlanDetailPage />} />
        <Route path="/media-plan/new" element={<CreateMediaPlanPage />} />
        <Route path="/media-plan/edit/:id" element={<EditMediaPlanPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/my-orders/:id" element={<OrderDetail />} />
        <Route path="/my-transactions" element={<MyTransactions />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/saved-products" element={<SavedProducts />} />
        <Route path="/saved-products/map" element={<SavedProductsMapPage />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-phone-number" element={<VerifyPhoneNumber />} />
        <Route path="/choose-your-account-type" element={<ChooseYourAccountType />} />
        <Route path="/fill-in-your-information/:type" element={<FillInYourInformation />} />
        <Route path="/ui-guide-line" element={<UiGuideLine />} />
        <Route path="*" element={<Navigate to="/home" />} />
        <Route path="/components" element={<Components />} />
      </Routes>
    </React.Suspense>
  )
}

export default MainRoutes
